import '../scss/main.scss';
import '../scss/news-insights.scss';
import './_common.js';

import Vue from './_vue-signleton';
import EntryFilter from './mixins/entry-filter';

Vue.setOptions({
  mixins: [EntryFilter]
});
